import Button from '@bit/grupo_avenida.components.button';
import Typography from '@bit/grupo_avenida.components.typography';
import {Box} from '@material-ui/core';
import {differenceInCalendarDays, parseISO} from 'date-fns';
import {max} from 'lodash';
import PropTypes from 'prop-types';
import {useContext} from 'react';

import {Trans} from '@lingui/react';

import {LoginContext} from '../context/config';
import Header from './Header';

const PasswordExpiration = ({classes, performLogin}) => {
  const {passwordExpirationDate} = useContext(LoginContext);
  const remainingDays = max([0, differenceInCalendarDays(parseISO(passwordExpirationDate), new Date())]);

  return (
    <div className={classes.stepWrapperRequest}>
      <Box>
        <Header classes={classes} title="Atenção" />
        <Box mb={3}>
          <Box mb={3}>
            <Typography color="neutral.level2" family="fontSecondary" lineHeight={18} size={14} tag="p">
              Sua senha de acesso está prestes a expirar e precisa ser atualizada nos próximos &nbsp;<strong>{remainingDays}</strong>&nbsp; dias. Após este período, não será mais possível acessar o
              sistema.
            </Typography>
          </Box>
          <Box mb={3}>
            <a rel="noreferrer" href={`${process.env.PUBLIC_URL}/pdf/Redefinição Senha.pdf`} target="_blank">
              <Typography color="secondary.main" family="fontSecondary" lineHeight={18} size={14} tag="span">
                <Trans id="login.passwordExpiration.moreInfo">Saiba mais informações</Trans>
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>

      <div className={classes.buttonLogin}>
        <Button onClick={performLogin} variant="dark">
          <Trans id="login.passwordExpiration.continue">Continuar</Trans>
        </Button>
      </div>
    </div>
  );
};

PasswordExpiration.propTypes = {
  classes: PropTypes.shape({
    buttonLogin: PropTypes.string,
    stepWrapperRequest: PropTypes.string
  }),
  performLogin: PropTypes.func.isRequired
};

export default PasswordExpiration;
