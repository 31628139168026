import PropTypes from 'prop-types';
import React from 'react';

import logo from './LogoConexao.svg';

const LogoConexao = ({className}) => {
  return (
    <div className={className}>
      <img src={logo} alt="Logo Conexao" />
    </div>
  );
};

LogoConexao.propTypes = {
  className: PropTypes.string
};

export default LogoConexao;
