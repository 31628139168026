import PropTypes from 'prop-types';
import React from 'react';

import logo from './LogoSiga.svg';

const LogoSiga = ({className}) => {
  return (
    <div className={className}>
      <img src={logo} alt="Logo Siga" />
    </div>
  );
};

LogoSiga.propTypes = {
  className: PropTypes.string
};

export default LogoSiga;
