import {React} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {MenuItem, FormControl, Select, InputBase} from '@material-ui/core';
import {i18n} from '@lingui/core';

import {Us, Br} from 'react-flags-select';

import DynamicActivate from '../../app/language/utils';

const BootstrapInput = withStyles(theme => ({
  input: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'theme.palette.background.paper',
    border: '1px solid #ced4da',
    fontSize: 12,
    fontFamily: 'Roboto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#FFCC32',
      boxShadow: '0 0 0 0.2rem rgba(255, 204, 50, .25)'
    }
  }
}))(InputBase);

function LanguageSelector() {
  const handleChange = async event => {
    if (!event.target.value) return;
    DynamicActivate(event.target.value);
  };

  return (
    <FormControl variant="outlined">
      <Select labelId="language-label" id="language-select" value={i18n._locale || 'pt'} onClick={handleChange} input={<BootstrapInput />}>
        <MenuItem value="pt">
          <Br /> &nbsp; Português (PT-BR)
        </MenuItem>
        <MenuItem value="en">
          <Us /> &nbsp; English (EN-US)
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default LanguageSelector;
