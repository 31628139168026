const styles = theme => ({
  //buttons
  buttonRequest: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  buttonLogin: {
    display: 'flex',
    alignContent: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  buttonStepLogin: {
    display: 'flex',
    alignContent: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > button': {
      minWidth: '96px !important'
    }
  },
  buttonBase: {
    borderRadius: '5px !important',
    color: '#FFF',
    backgroundColor: '#2E0D1E',
    '& button:disabled': {
      borderRadius: '5px !important'
    }
  },
  infoMessage: {
    marginBottom: theme.spacing(3)
  },
  form: {
    marginBottom: theme.spacing(4),
    '& > div:first-child': {
      marginBottom: theme.spacing(2)
    }
  },
  stepWrapperRequest: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%'
  },
  stepWrapperLogin: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  title: {
    marginBottom: `${theme.spacing(3)}px !important`
  },
  titleWrapper: {
    borderBottom: `1px solid ${theme.colors.neutral.level9}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  containerStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  verifyInput: {
    border: `1px solid ${theme.colors.neutral.level9}`,
    borderRadius: '5px',
    fontSize: '20px',
    fontFamily: theme.fonts.fontSecondary,
    color: theme.colors.neutral.level1,
    padding: 0,
    minWidth: '38px',
    minHeight: '38px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '30px',
      minHeight: '30px'
    }
  },
  verifyInputError: {
    border: `1px solid ${theme.colors.error.main}`
  },
  verifyInputFocus: {
    outlineColor: theme.colors.primary.main
  },
  verifyLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: `${theme.spacing(2)}px !important`
  },
  link: {
    fontFamily: theme.fonts.fontSecondary,
    fontSize: 14,
    fontWeight: 500,
    color: theme.colors.neutral.level5
  },
  radioLabel: {
    fontFamily: theme.fonts.fontSecondary,
    fontSize: 14,
    color: theme.colors.neutral.level2,
    textTransform: 'capitalize'
  },
  verificationInputLabel: {
    marginBottom: theme.spacing(1)
  },
  resendPinCircularProgress: {
    marginRight: theme.spacing(0.5),
    color: theme.colors.neutral.level6
  }
});

export default styles;
