import BaseService from '../../service';

class LoginService extends BaseService {
  prefix = 'login/v2';

  async requestLogin({username, password}) {
    return await this.post(this.prefix, {username, password});
  }

  async requestPin(payload) {
    const {type, username} = payload;
    return await this.post(`${this.prefix}/${type}`, {username});
  }

  async verifyPin(payload) {
    const {pin, type, username} = payload;
    return await this.post(`${this.prefix}/${type}/verify`, {username, code: pin});
  }

  async verifyToken() {
    const response = await this.post('login/token/decode', {}, {timeout: 1000});
    const {roles, token, name, writableStores, userStore} = response;
    return {roles, name, writableStores, userStore, token};
  }

  async updateToken() {
    const response = await this.post('login/token/refresh');
    const {roles, name, token, writableStores, userStore} = response;
    return {roles, name, token, writableStores, userStore};
  }

  async logoutUser() {
    await this.post({}, '/logout');
  }
}

const instance = new LoginService();

export default instance;
