import {createContext} from 'react';

import {LOADING} from '../../utils/steps';

export const initialContext = {
  step: LOADING,
  username: '',
  password: '',
  passwordExpirationDate: null,
  authOptions: [],
  selectedAuthOption: '',
  pinDestination: '',
  errorMessage: '',
  infoMessage: '',
  setContext: config => config
};

export const LoginContext = createContext(initialContext);
