import {addDays, isBefore, parseISO} from 'date-fns';

const WARNING_PERIOD_IN_DAYS = 5;

// Verifica se a senha do usuário está para expirar nos próximos 5 dias
export const passwordAboutToExpire = expirationDate => {
  if (!expirationDate) return false;
  const limitDate = addDays(new Date(), WARNING_PERIOD_IN_DAYS);
  return isBefore(parseISO(expirationDate), limitDate);
};
