import {withStyles} from '@material-ui/core/styles';
import {isArray, size} from 'lodash';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import styles from '../../assets/styles/styles';
import {LOGIN, REQUEST_PIN, VERIFY_PIN, WARNING_PASSWORD_EXPIRATION} from '../../utils/steps';
import StepLogin from '../components/StepLogin';
import StepRequestPin from '../components/StepRequestPin';
import StepVerifyPin from '../components/StepVerifyPin';
import PasswordExpiration from '../components/PasswordExpiration';
import {LoginContext} from '../context/config';
import {passwordAboutToExpire} from '../util';

const LoginSteps = props => {
  const {classes, performLogin} = props;
  const {step, authOptions, setContext} = useContext(LoginContext);

  const goToStep = step => {
    setContext({step});
  };

  const handlePasswordExpiration = data => {
    if (!passwordAboutToExpire(data.passwordExpireDate)) {
      return performLogin();
    }

    setContext({passwordExpirationDate: data.passwordExpireDate, step: WARNING_PASSWORD_EXPIRATION});
  };

  const onLoginSuccess = ({username, data}) => {
    // TODO: remover este check quando habilitar 2FA para todos
    if (isArray(data)) {
      // 2FA habilitado
      setContext({username, authOptions: data, step: REQUEST_PIN});
    } else if (data.token) {
      setContext({username});
      handlePasswordExpiration(data);
    }
  };

  const onRequestPinSuccess = ({selectedAuthOption, pinDestination}) => {
    setContext({selectedAuthOption, pinDestination, step: VERIFY_PIN});
  };

  const onRequestPinGoBack = () => {
    goToStep(LOGIN);
  };

  const onVerifyPinGoBack = () => {
    if (size(authOptions) === 1) {
      goToStep(LOGIN);
      return;
    }
    goToStep(REQUEST_PIN);
  };

  switch (step) {
    case REQUEST_PIN:
      return <StepRequestPin classes={classes} onGoBack={onRequestPinGoBack} onSuccess={onRequestPinSuccess} />;
    case VERIFY_PIN:
      return <StepVerifyPin classes={classes} onGoBack={onVerifyPinGoBack} goToBegin={() => goToStep(LOGIN)} onSuccess={handlePasswordExpiration} />;
    case WARNING_PASSWORD_EXPIRATION:
      return <PasswordExpiration classes={classes} performLogin={performLogin} />;
    case LOGIN:
    default:
      return <StepLogin classes={classes} onSuccess={onLoginSuccess} />;
  }
};

LoginSteps.propTypes = {
  classes: PropTypes.object,
  performLogin: PropTypes.func
};

export default withStyles(styles)(LoginSteps);
