import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import LogoConexao from '../assets/LogoConexao';
import LogoSiga from '../assets/LogoSiga';
import {ReactComponent as LogoGav} from '../assets/LogoGrupoAvenida.svg';
import LogoAvenida from '../assets/LogoAvenida';

const styles = theme => ({
  logoGav: {
    margin: theme.spacing(4, 0, 3, 0),
    width: 272,
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(6, 0, 0, 0)
    }
  },
  logosWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  logoAvenida: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& img': {
      marginBottom: theme.spacing(2),
      width: 92
    }
  },
  logoConexao: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& img': {
      marginTop: '-2px',
      marginBottom: theme.spacing(2),
      width: 92
    }
  },
  logoSiga: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& img': {
      marginBottom: theme.spacing(2),
      width: 45
    }
  }
});

const Logos = ({classes}) => {
  return (
    <div>
      <div className={classes.logoGav}>
        <LogoGav />
      </div>
      <div className={classes.logosWrapper}>
        <LogoAvenida className={classes.logoAvenida} />
        <LogoSiga className={classes.logoSiga} />
        <LogoConexao className={classes.logoConexao} />
      </div>
    </div>
  );
};

export default withStyles(styles)(Logos);
