import Button from '@bit/grupo_avenida.components.button';
import Typography from '@bit/grupo_avenida.components.typography';
import {Box} from '@material-ui/core';
import {size} from 'lodash';
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import OtpInput from 'react-otp-input';

import {Trans} from '@lingui/react';

import {verifyPin} from '../actions/LoginActions';
import {LoginContext} from '../context/config';
import ErrorMessage from './ErrorMessage';
import Header from './Header';
import ResendPin from './ResendPin';

const Text = ({children}) => {
  return (
    <Typography color="neutral.level2" family="fontSecondary" lineHeight={18} size={14} tag="p">
      {children}
    </Typography>
  );
};

Text.propTypes = {
  children: PropTypes.node
};

const StepVerifyPin = ({classes, onSuccess, onGoBack, goToBegin}) => {
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);

  const {username, pinDestination, selectedAuthOption, errorMessage, setContext} = useContext(LoginContext);

  const handleChange = value => {
    setPin(value);
    setContext({errorMessage: ''});
  };

  const submit = () => {
    setLoading(true);
    setContext({errorMessage: ''});
    verifyPin({type: selectedAuthOption, pin, username})
      .then(onSuccess)
      .catch(e => {
        if (e.expired) {
          goToBegin();
          setContext({infoMessage: e.message});
        } else {
          setContext({errorMessage: e.message});
          setLoading(false);
        }
      });
  };

  const handleGoBack = () => {
    setContext({errorMessage: ''});
    onGoBack();
  };

  return (
    <div className={classes.stepWrapperRequest}>
      <Box>
        <Header classes={classes} title="Verificação de conta" subtitle="(etapa 2 de 2)" />
        <Box mb={3}>
          <Box mb={2}>
            <Text>
              <Trans id="login.stepVerifyPin.messageVerification">Uma mensagem com código de verificação foi enviada para</Trans> <b>{pinDestination}</b>.
            </Text>
          </Box>
          <Box mb={3}>
            <Text>
              <Trans id="login.stepVerifyPin.insertVerify">Insira no campo abaixo o código de verificação de seis dígitos.</Trans>
            </Text>
          </Box>
          <form>
            <OtpInput
              value={pin}
              shouldAutoFocus
              onChange={handleChange}
              isDisabled={loading || pinLoading}
              numInputs={6}
              isInputNum
              separator={<span></span>}
              hasErrored={errorMessage}
              containerStyle={classes.containerStyle}
              errorStyle={classes.verifyInputError}
              inputStyle={classes.verifyInput}
              focusStyle={classes.verifyInputFocus}
            />
          </form>
        </Box>
        <ResendPin classes={classes} goToBegin={goToBegin} loading={loading} pinLoading={pinLoading} setPinLoading={setPinLoading} />
      </Box>
      <Box>
        <ErrorMessage message={errorMessage} />
        <div className={classes.buttonRequest}>
          <Button disabled={Boolean(loading || pinLoading)} variant="secondary" onClick={handleGoBack} responsive={false}>
            <Trans id="login.stepVerifyPin.back">Voltar</Trans>
          </Button>
          <Button disabled={Boolean(loading || size(pin) !== 6 || pinLoading || errorMessage)} loading={loading} variant="dark" onClick={submit} responsive={false}>
            <Trans id="login.stepVerifyPin.continue">Continuar</Trans>
          </Button>
        </div>
      </Box>
    </div>
  );
};

StepVerifyPin.propTypes = {
  classes: PropTypes.shape({
    buttonRequest: PropTypes.string,
    stepWrapperRequest: PropTypes.string,
    verifyInput: PropTypes.string,
    verifyLabel: PropTypes.string,
    verificationInputLabel: PropTypes.string,
    verifyInputError: PropTypes.string,
    verifyInputFocus: PropTypes.string
  }),
  goToBegin: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default StepVerifyPin;
