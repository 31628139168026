/* eslint-disable no-throw-literal */
import Cookies from 'js-cookie';
import {size} from 'lodash';
import LoginService from '../service/LoginService';

const throwException = (message, expired = false) => {
  throw {message, expired};
};

// Inicia processo de login
export const login = async request => {
  return await LoginService.requestLogin(request)
    .then(data => {
      if (data) {
        if (size(data.authOptions) > 0) {
          return data.authOptions;
        } else if (data.token) {
          // usuario está com o 2FA desabilitado
          // TODO: remover este check quando habilitar 2FA para todos
          return data;
        }
      }
      // Não foi possível encontrar métodos de autenticação de segunda etapa para o usuário
      throw 'noAuthOptions';
    })
    .catch(err => {
      const {response} = err;
      if (response && (response.status === 403 || response.status === 401)) {
        throwException('Usuário e/ou senha inválidos');
      } else if (err === 'noAuthOptions') {
        throwException('Autenticação desabilitada. Entre em contato com o suporte.');
      }
      throwException('Ocorreu um erro desconhecido. Por favor, tente novamente.');
    });
};

// Envia o PIN para o usuario
export const requestPin = async request => {
  return await LoginService.requestPin(request).catch(({response}) => {
    if (response && response.status === 504) {
      throwException('Ocorreu um erro ao enviar o PIN. Por favor, tente novamente.');
    } else if (response && response.status === 406) {
      throwException('Sessão expirada. Por favor, faça login novamente.', true);
    }
    throwException('Ocorreu um erro desconhecido. Por favor, tente novamente.');
  });
};

// Valida PIN e faz login do usuário
export const verifyPin = async request => {
  return await LoginService.verifyPin(request).catch(({response}) => {
    if (response) {
      if (response.status === 403) {
        throwException('Código incorreto. Verifique o código recebido e tente novamente.');
      } else if (response.status === 504) {
        throwException('Ocorreu um erro ao validar o código. Por favor, tente novamente.');
      } else if (response.status === 406) {
        throwException('Código de verificação expirado. Por favor, faça login novamente.', true);
      }
    }
    throwException('Ocorreu um erro desconhecido. Por favor, tente novamente.');
  });
};

export const performLogin = payload => async dispatch => {
  // dispatch({type: loginTypes.LOGIN_SUCCESS, payload});
  // const {path} = payload;
  // dispatch(push(path || '/'));
};

export const verifyToken = async () => {
  const authStatus = Cookies.get('auth-status');
  return new Promise((resolve, reject) => {
    if (authStatus === 'logged') {
      LoginService.verifyToken()
        .then(resolve)
        .catch(reject);
      return;
    }
    return reject('Not logged in');
  });
};

export const updateToken = () => async dispatch => {
  // dispatch({type: loginTypes.TOKEN_UPDATE});
  // try {
  //   const payload = await LoginService.updateToken();
  //   dispatch({type: loginTypes.TOKEN_SUCCESS, payload});
  // } catch (e) {
  //   showError('Ocorreu um erro ao atualizar sua sessão. Por favor, logue novamente')(dispatch);
  //   dispatch(push('/login'));
  //   dispatch({type: loginTypes.TOKEN_FAILURE});
  // }
};

export const logOutUser = () => async dispatch => {
  // Cookies.remove('auth-status');
  // Cookies.remove('auth-status', {domain: '.avenida.com.br'});
  // dispatch({type: loginTypes.LOGOUT});
  // LoginService.logoutUser().finally(() => dispatch({type: FILTER_CLEAR}));
};
