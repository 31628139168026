import Typography from '@bit/grupo_avenida.components.typography';
import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const ErrorMessage = ({message}) => {
  if (!message) return null;
  return (
    <Box mb={2}>
      <Typography color="error.main" family="fontSecondary" size={14}>
        {message}
      </Typography>
    </Box>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string
};

export default ErrorMessage;
