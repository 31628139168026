import queryString from 'query-string';
import service from '../service';

export const redirectUser = async ({callbackUrl}: any) => {
  window.location = callbackUrl;
};

type Callback = {
  site?: string;
};

export const verifyCallbackUrl = async () =>
  new Promise((resolve, reject) => {
    const query = queryString.parse(window.location.search);
    if (!query.callbackUrl) {
      return reject('invalidCallback');
    }

    service
      .validateCallback<Callback>(query.callbackUrl as string)
      .then(({site}) => {
        return resolve({site, callbackUrl: query.callbackUrl});
      })
      .catch(() => reject('invalidCallback'));
  });
