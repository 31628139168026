import Button from '@bit/grupo_avenida.components.button';
import Typography from '@bit/grupo_avenida.components.typography';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {size} from 'lodash';
import PropTypes from 'prop-types';
import React, {useCallback, useContext, useEffect, useState} from 'react';

import {Trans} from '@lingui/react';
import {t} from '@lingui/macro';

import {requestPin} from '../actions/LoginActions';
import {LoginContext} from '../context/config';
import ErrorMessage from './ErrorMessage';
import Header from './Header';

const StepRequestPin = ({classes, onGoBack, onSuccess}) => {
  const {authOptions, errorMessage, username, setContext} = useContext(LoginContext);

  const [type, setType] = useState(authOptions[0]);
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    setType(event.target.value);
  };

  const handleSubmit = useCallback(() => {
    setContext({errorMessage: ''});
    setLoading(true);
    requestPin({username, type})
      .then(({to}) => {
        onSuccess({selectedAuthOption: type, pinDestination: to});
      })
      .catch(e => {
        if (e.expired) {
          onGoBack();
        }
        setContext({errorMessage: e.message});
        setLoading(false);
      });
  }, [type, username, onSuccess, onGoBack, setContext]);

  const handleGoBack = () => {
    setContext({errorMessage: ''});
    onGoBack();
  };

  useEffect(() => {
    if (size(authOptions) === 1 && !loading && !errorMessage) {
      handleSubmit();
    }
  }, [authOptions, loading, handleSubmit, errorMessage]);

  return (
    <div className={classes.stepWrapperRequest}>
      <Box>
        <Header classes={classes} title="Verificação de conta" subtitle="(etapa 1 de 2)" />
        <Box mb={3}>
          <Typography color="neutral.level2" family="fontSecondary" lineHeight={18} size={14} tag="p" weight="bold">
            <Trans id="login.stepRequestPin.pinVerify">Como devemos enviar o código de verificação?</Trans>
          </Typography>
        </Box>
        <RadioGroup aria-label="tipo autenticação" name="type" value={type} onChange={handleChange}>
          {authOptions.map(option => (
            <FormControlLabel key={option} value={option} label={option} classes={{label: classes.radioLabel}} control={<Radio size="small" disabled={loading} />} />
          ))}
        </RadioGroup>
      </Box>
      <Box>
        <ErrorMessage message={errorMessage} />
        <div className={classes.buttonRequest}>
          <Button variant="secondary" onClick={handleGoBack} disabled={loading} responsive={false}>
            <Trans id="login.stepRequestPin.back">Voltar</Trans>
          </Button>
          <Button variant="dark" onClick={handleSubmit} disabled={loading} loading={loading} responsive={false}>
            {errorMessage ? t`login.stepRequestPin.tryAgain` : t`login.stepRequestPin.continue`}
          </Button>
        </div>
      </Box>
    </div>
  );
};

StepRequestPin.propTypes = {
  classes: PropTypes.shape({
    buttonRequest: PropTypes.string,
    radio: PropTypes.string,
    radioItem: PropTypes.string,
    radioLabel: PropTypes.string,
    stepWrapperRequest: PropTypes.string
  }),
  onGoBack: PropTypes.func,
  onSuccess: PropTypes.func
};
export default StepRequestPin;
