import PropTypes from 'prop-types';
import React from 'react';

import logo from './LogoAvenida.svg';

const LogoAvenida = ({className}) => {
  return (
    <div className={className}>
      <img src={logo} alt="Logo Avenida" />
    </div>
  );
};

LogoAvenida.propTypes = {
  className: PropTypes.string
};

export default LogoAvenida;
