import {i18n} from '@lingui/core';
import {I18nProvider} from '@lingui/react';
import {en, pt} from 'make-plural/plurals';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import DynamicActivate from './utils';

const pluralZero = fn => (n, ord) => (n === 0 ? 'zero' : fn(n, ord));

i18n.loadLocaleData({
  en: {plurals: pluralZero(en)},
  pt: {plurals: pluralZero(pt)}
});

const LanguageProvider = ({children, language}) => {
  useEffect(() => DynamicActivate(language), [language]);
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

LanguageProvider.propTypes = {
  children: PropTypes.node,
  language: PropTypes.oneOf(['pt', 'en'])
};

export default LanguageProvider;
