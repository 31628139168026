import {CircularProgress, Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import React from 'react';
import Login from '../login';
import {verifyToken as verifyLoggedIn} from '../login/actions/LoginActions';
import LoginContextComponent from '../login/context';
import {redirectUser, verifyCallbackUrl} from './actions';
import Error from './Error';
import Logos from './Logos';
import LogosMobile from './LogosMobile';
import styles from './styles';

class App extends React.Component {
  state = {
    callbackUrl: '',
    site: 'siga',
    error: false,
    loading: true,
    redirect: false
  };

  componentDidMount() {
    verifyLoggedIn()
      .then(verifyCallbackUrl)
      .then(redirectUser)
      .catch(e => {
        if ('invalidCallback' === e) {
          this.setState({error: true});
          return;
        }
        verifyCallbackUrl()
          .then(({site}) => this.setState({site, loading: false}))
          .catch(() => this.setState({error: true}));
      });
  }

  isLoading = () => {
    const {loading, redirect} = this.state;
    return loading || redirect;
  };

  performLogin = () => {
    this.setState({redirect: true}, () => {
      verifyCallbackUrl()
        .then(redirectUser)
        .catch(() => this.setState({error: true}));
    });
  };

  renderContent = () => {
    if (this.state.error) {
      return <Error />;
    }

    if (this.isLoading()) {
      return <CircularProgress size={50} />;
    }
    return (
      <LoginContextComponent>
        <Login performLogin={this.performLogin} />
      </LoginContextComponent>
    );
  };

  render() {
    const {classes} = this.props;
    return (
      <Grid container className={classes.container}>
        <Grid item md={6} className={classes.logoWrapper}>
          <Logos />
        </Grid>
        <Grid item md={6} className={classes.item}>
          <div className={classes.formLogoWrapper}>
            <div className={classnames(classes.wrap, this.isLoading() || this.state.error ? classes.redirectLoading : null)}>{this.renderContent()}</div>
          </div>
          <LogosMobile />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(App);
