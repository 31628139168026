import BaseService from '../../service';

class AppService extends BaseService {
  prefix = 'validate';

  async validateCallback<T>(callbackUrl: string) {
    return this.get<T>(`${this.prefix}?callbackUrl=${callbackUrl}`);
  }
}

const instance = new AppService();
export default instance;
