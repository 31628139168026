import Button from '@bit/grupo_avenida.components.button';
import Typography from '@bit/grupo_avenida.components.typography';
import Input from '@bit/grupo_avenida.components.input';
import {trim} from 'lodash';
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {Trans} from '@lingui/react';
import {t} from '@lingui/macro';

import {login} from '../actions/LoginActions';
import {LoginContext} from '../context/config';
import LanguageSelector from './LanguageSelector';

const StepLogin = ({classes, onSuccess}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const {infoMessage, errorMessage, setContext} = useContext(LoginContext);

  const handleChange = setter => value => {
    setter(value);
    if (errorMessage || infoMessage) {
      setContext({errorMessage: '', infoMessage: ''});
    }
  };

  const handleSubmit = () => {
    // clear states
    setContext({errorMessage: ''});
    setLoading(true);
    // execute login action
    login({username, password})
      .then(data => {
        onSuccess({data, username});
      })
      .catch(e => {
        setContext({errorMessage: e.message});
        setLoading(false);
      });
  };

  const submitDisabled = !trim(username) || !trim(password) || loading;

  const handleEnter = e => {
    const {keyCode} = e;
    if (!submitDisabled && keyCode === 13) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className={classes.stepWrapperLogin}>
      <Typography size={18} className={classes.title} weight="medium">
        <Trans id="login.stepLogin.accountAccess">Acessar Conta</Trans>
      </Typography>
      {infoMessage && (
        <div className={classes.infoMessage}>
          <Typography family="fontSecondary" color="error.main">
            {infoMessage}
          </Typography>
        </div>
      )}
      <form className={classes.form}>
        <Input
          autoComplete="username"
          disabled={loading}
          error={!!errorMessage}
          name="username"
          inputProps={{
            onKeyDown: handleEnter
          }}
          onChange={handleChange(setUsername)}
          placeholder={t`login.stepLogin.userName`}
          title={t`login.stepLogin.user`}
          value={username}
          variant="outlined"
        />
        <Input
          autoComplete="password"
          disabled={loading}
          error={!!errorMessage}
          message={errorMessage}
          name="password"
          inputProps={{
            onKeyDown: handleEnter
          }}
          onChange={handleChange(setPassword)}
          placeholder={t`login.stepLogin.enterPassword`}
          title={t`login.stepLogin.password`}
          type="password"
          value={password}
          variant="outlined"
        />
      </form>
      <div className={classes.buttonStepLogin}>
        <LanguageSelector language="pt" />

        <Button className={classes.buttonBase} onClick={handleSubmit} disabled={submitDisabled} loading={loading} variant="dark">
          <Trans id="login.stepLogin.enter">Entrar</Trans>
        </Button>
      </div>
    </div>
  );
};

StepLogin.propTypes = {
  classes: PropTypes.shape({
    buttonLogin: PropTypes.string,
    form: PropTypes.string,
    stepWrapperLogin: PropTypes.string,
    titleWrapper: PropTypes.string
  }),
  loading: PropTypes.bool,
  onSuccess: PropTypes.func,
  site: PropTypes.object
};

export default StepLogin;
