import {i18n} from '@lingui/core';

const DynamicActivate = async language => {
  const selectedLanguage = language || 'pt';
  // ESLINT doesn't like parsing string on dynamic import https://stackoverflow.com/a/60593353
  const {messages} = await import('../../locales/' + selectedLanguage + '/messages');
  i18n.load(selectedLanguage, messages);
  i18n.activate(selectedLanguage);
};

export default DynamicActivate;
