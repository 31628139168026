import {merge} from 'lodash';
import React from 'react';

import {LoginContext, initialContext} from './config';

class LoginContextComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {...initialContext, setContext: this.setContext};
  }

  setContext = config => {
    this.setState({...merge(this.state, config)});
  };

  render() {
    const {children} = this.props;

    return <LoginContext.Provider value={this.state}>{children}</LoginContext.Provider>;
  }
}

export default LoginContextComponent;
