import Typography from '@bit/grupo_avenida.components.typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Check from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';

import {Trans} from '@lingui/react';

import {requestPin} from '../actions/LoginActions';
import {LoginContext} from '../context/config';

const resendPinDelay = 30;
const messageStyledProps = {
  color: 'neutral.level6',
  family: 'fontSecondary',
  lineHeight: 18,
  size: 14,
  tag: 'p',
  weight: 'medium'
};

const ResendPin = ({classes, loading, pinLoading, setPinLoading, goToBegin}) => {
  const [timeLeft, setTimeleft] = useState(resendPinDelay);
  const [pinSent, setPinSent] = useState(false);
  const timer = useRef();

  const {username, selectedAuthOption, setContext} = useContext(LoginContext);

  const runTimer = useCallback(() => {
    setTimeleft(timeLeft - 1);
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft > 0) {
      timer.current = setInterval(runTimer, 1000);
    } else {
      setPinSent(false);
      clearInterval(timer.current); // Não precisamos do timer depois que chega em 0
    }
    return () => clearInterval(timer.current);
  }, [runTimer, pinSent, timeLeft]);

  const resendCode = () => {
    setPinLoading(true);
    requestPin({username, type: selectedAuthOption})
      .then(() => {
        setPinLoading(false);
        setTimeleft(resendPinDelay);
        setPinSent(true);
      })
      .catch(e => {
        if (e.expired) {
          goToBegin();
        } else {
          setPinLoading(false);
        }
        setContext({errorMessage: e.message});
      });
  };

  const hasTimeLeft = timeLeft > 0;

  // Codigo reenviado
  if (pinSent) {
    return (
      <Typography className={classes.verifyLabel} {...messageStyledProps}>
        <Check fontSize="small" />
        <Trans id="login.resendPin.codeSent">Código enviado</Trans>
      </Typography>
    );
  }

  // Solicitação de novo código em andamento
  if (pinLoading) {
    return (
      <Box display="flex" flexDirection="row">
        <CircularProgress size={18} className={classes.resendPinCircularProgress} />
        <Typography className={classes.verifyLabel} {...messageStyledProps}>
          <Trans id="login.resendPin.sent">Enviado</Trans>
        </Typography>
      </Box>
    );
  }

  // Aguardando timeout para reenviar o código
  if (hasTimeLeft) {
    return (
      <Typography className={classes.verifyLabel} {...messageStyledProps}>
        Aguarde {timeLeft}s para reenviar o código
      </Typography>
    );
  }

  if (loading) {
    return (
      <Typography className={classes.verifyLabel} {...messageStyledProps} color={messageStyledProps.color}>
        <Trans id="login.resendPin.requestCode">Solicitar novo código</Trans>
      </Typography>
    );
  }

  return (
    <Typography className={classes.verifyLabel} {...messageStyledProps} color="neutral.level5">
      <Link className={classes.link} component="button" underline="hover" onClick={resendCode}>
        <Trans id="login.resendPin.requestCode">Solicitar novo código</Trans>
      </Link>
    </Typography>
  );
};

ResendPin.propTypes = {
  classes: PropTypes.shape({
    verifyLabel: PropTypes.string,
    link: PropTypes.string,
    resendPinCircularProgress: PropTypes.string
  }),
  loading: PropTypes.bool.isRequired,
  goToBegin: PropTypes.func.isRequired,
  pinLoading: PropTypes.bool.isRequired,
  setPinLoading: PropTypes.func.isRequired
};

export default ResendPin;
