import Button from '@bit/grupo_avenida.components.button';
import Typography from '@bit/grupo_avenida.components.typography';
import {withStyles} from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {Trans} from '@lingui/react';

class ErrorMessage extends Component {
  render() {
    const {classes} = this.props;
    return (
      <div>
        <div className={classes.errorMessage}>
          <WarningIcon className={classes.warningIcon} />
        </div>
        <div className={classes.warningMessage}>
          <Typography lineHeight={24} size={14}>
            <Trans id="login.error.processError">Ocorreu um erro ao processar a sua solicitação. Por favor, tente novamente.</Trans>
          </Typography>
        </div>
        <div className={classes.tryAgain}>
          <Button onClick={() => window.history.back()} size="medium" variant="dark">
            <Trans id="login.error.tryAgain">Tentar novamente</Trans>
          </Button>
        </div>
        <div className={classes.errorMessage}>
          <Typography lineHeight={14} size={12}>
            <Trans id="login.error.problemPersists">Caso o problema persista, entre em contato com</Trans> <b>suporte@grupoavenida.com.br</b>
          </Typography>
        </div>
      </div>
    );
  }
}

ErrorMessage.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  errorMessage: {
    textAlign: 'center'
  },
  tryAgain: {
    marginBottom: '50px',
    textAlign: 'center'
  },
  warningMessage: {
    marginBottom: '20px',
    textAlign: 'center'
  },
  warningIcon: {
    fontSize: '40px',
    color: theme.colors.neutral.level7
  }
});

export default withStyles(styles)(ErrorMessage);
