const styles = theme => ({
  container: {
    alignContent: 'start',
    backgroundColor: theme.colors.primary.main,
    minHeight: '100vh',
    flexGrow: 1,
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'row'
    },
    transition: 'background-color 0.4s ease'
  },
  formLogoWrapper: {
    display: 'flex',
    flexGrow: 1,
    maxWidth: '340px',
    flexDirection: 'column',
    width: `calc(100% - ${theme.spacing(4)}px)`,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.spacing(10)}px - ${theme.spacing(6)}px)`,
      padding: theme.spacing(5)
    }
  },
  item: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%'
  },
  logoWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  redirectLoading: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  wrap: {
    background: theme.colors.neutral.white,
    border: `1px solid ${theme.colors.neutral.level9}`,
    borderRadius: '10px',
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(5, 3, 5, 3)
  }
});

export default styles;
