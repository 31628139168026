import axios, {AxiosResponse, Method} from 'axios';

type Headers = {
  'Content-Type'?: string;
};

type RequestOptions = {
  headers?: Headers;
  body?: any;
  method?: Method;
  timeout?: number;
};

class BaseService {
  async fetch<T>(url: string, options: RequestOptions = {}) {
    const requestOp = {...options};
    requestOp.headers = options.headers || {};

    if (!requestOp.headers['Content-Type']) {
      requestOp.headers['Content-Type'] = 'application/json';
    }

    const {body: requestBody, ...newRequestOp} = requestOp;

    const res: AxiosResponse<T> = await axios({
      url: `${this.url}/${url}`,
      data: requestBody,
      ...newRequestOp
    });
    return await res.data;
  }

  get<T>(url: string, options?: RequestOptions) {
    return this.fetch<T>(url, options);
  }

  post<T>(url: string, body: any, options?: RequestOptions) {
    return this.fetch<T>(url, {...options, method: 'POST', body});
  }

  put<T>(url: string, body?: any) {
    return this.fetch<T>(url, {method: 'PUT', body});
  }

  patch<T>(url: string, body?: any) {
    return this.fetch<T>(url, {method: 'PATCH', body});
  }

  remove<T>(url: string, body?: any) {
    return this.fetch<T>(url, {method: 'DELETE', body});
  }

  get url() {
    return process.env.REACT_APP_API_URL;
  }
}

export default BaseService;
