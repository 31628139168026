import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import LogoAvenida from '../assets/LogoAvenida';
import LogoConexao from '../assets/LogoConexao';
import LogoSiga from '../assets/LogoSiga';

const styles = theme => ({
  logosWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '340px',
    width: `calc(100% - ${theme.spacing(6)}px)`,
    padding: theme.spacing(2, 0, 2, 0),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  logoAvenida: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& img': {
      marginBottom: theme.spacing(2),
      width: 92
    }
  },
  logoConexao: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& img': {
      marginTop: '-2px',
      marginBottom: theme.spacing(2),
      width: 92
    }
  },
  logoSiga: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& img': {
      marginBottom: theme.spacing(2),
      width: 45
    }
  }
});

const Logos = ({classes}) => {
  return (
    <div className={classes.logosWrapper}>
      <LogoAvenida className={classes.logoAvenida} />
      <LogoSiga className={classes.logoSiga} />
      <LogoConexao className={classes.logoConexao} />
    </div>
  );
};

export default withStyles(styles)(Logos);
