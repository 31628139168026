import Typography from '@bit/grupo_avenida.components.typography';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({classes, title, subtitle}) => (
  <div className={classes.titleWrapper}>
    <Typography color="secondary.main" lineHeight={22} size={18} tag="h2" weight="medium">
      {title}
    </Typography>
    <Typography color="neutral.level5" lineHeight={18} size={14} tag="p" weight="medium">
      {subtitle}
    </Typography>
  </div>
);

Header.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default Header;
